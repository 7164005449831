import React from "react";

interface IPersistScrollPositionProps {
    updateScrollPosition: boolean;
}



/**
 * Restore scroll position
 * 
 * @param  props
 */
const PersistScrollPosition = (props: IPersistScrollPositionProps) => {
    const { updateScrollPosition } = props;


    // Set vertical scroll position after rendering the page 
    const scrollToPosition = () => {
        if (updateScrollPosition) {
            const pathname = window.location.pathname;
            const top = sessionStorage.getItem(pathname);
            if (top !== null) {
                setTimeout(() => window.scrollTo(0, +top), 0);
                sessionStorage.removeItem(pathname);
            }
        }
    };

    return (
        <>{scrollToPosition()}</>
    );
};



/**
 * Store page scroll position
 * 
 */
const StoreScrollPosition = () => {
    const pathname = window.location.pathname;
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    sessionStorage.setItem(pathname, top.toString());
};


export default PersistScrollPosition;
export { StoreScrollPosition };
