import React, { useEffect, useState, useRef, MouseEvent } from "react";
import "./FundChart.css";
import axios from "axios";
import Cookies from "js-cookie";
import { TChartCloseClick, IChartShow } from "../../Router";
import { ChartProps, ReactChart } from "chartjs-react";
import { LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend, ChartData } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

ReactChart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend, annotationPlugin);


interface IFundPrice {
    date: string;
    price: number;
    // atr: number;
    ema200: number;
}


interface IChartResponse {
    name: string;
    yearIndex: number;
    fundPrices: IFundPrice[];
}

export interface IFundChartProps {
    onChartShow: IChartShow;
    onChartClose: TChartCloseClick;
}


const FundChart = (props: IFundChartProps) => {

    const { onChartShow: { fundId, compareFundId }, onChartClose } = props;

    const [chartOption, setChartOption] = useState<ChartProps | undefined>(undefined);
    const [chartData, setChartData] = useState<ChartData | undefined>(undefined);

    const visibility = fundId ? "show" : "hide";
    const isInitialMount = useRef(true);


    /**
    * Render Single Fund chart
    */
    const renderSingleFundChart = (response: IChartResponse) => {
        const { fundPrices, name, yearIndex } = response;

        setChartOption({
            plugins: {
                // @ts-expect-error TypeScript not yet compatible
                title: {
                    display: true,
                    text: name,
                    fontFamily: "Exo2-SemiBold",
                    font: {
                        size: 18
                    }
                },
                annotation: {
                    annotations: {
                        priceline: {
                            type: "line",
                            mode: "horizontal",
                            scaleID: "y",
                            value: 100,
                            borderWidth: 3,
                            borderColor: "darkgray",
                            drawTime: "beforeDatasetsDraw"
                        },
                        yearline: {
                            type: "line",
                            mode: "vertical",
                            scaleID: "x",
                            value: yearIndex,
                            borderColor: "gray",
                            drawTime: "afterDraw",
                            label: {
                                content: new Date().getFullYear(),
                                display: true,
                                position: "start"
                            }
                        }
                    }
                }
            },
            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
                enabled: false
            },
            scales: {
                y: {
                    ticks: {
                        callback: function (value: number) {
                            return value + "%";
                        }
                    }
                }
            }
        });

        setChartData({
            labels: fundPrices.map(x => x.date),
            datasets: [
                {
                    data: fundPrices.map(x => x.price),
                    label: "Price",
                    borderColor: "#c45850",
                    fill: false,
                    radius: 0,
                    // @ts-expect-error TypeScript not yet compatible
                    lineTension: 0.4
                },
                // {
                //     data: fundPrices.map(x => x.atr),
                //     label: "ATR",
                //     borderColor: "#3e95cd",
                //     fill: false,
                //     showLine: false
                // },
                {
                    data: fundPrices.map(x => x.ema200),
                    label: "EMA200",
                    borderColor: "#bbbbbb",
                    fill: false,
                    borderDash: [10, 5],
                    radius: 0,
                    // @ts-expect-error TypeScript not yet compatible
                    lineTension: 0.4
                }
            ]
        });
    };



    /**
     * Render Fund Comparison chart
     */
    const renderComparisonFundChart = (response: IChartResponse[]) => {

        const [source, target] = response;

        const yearIndex: number = source.yearIndex;

        setChartOption({
            plugins: {
                // @ts-expect-error TypeScript not yet compatible
                title: {
                    display: false
                },
                annotation: {
                    annotations: [{
                        type: "line",
                        mode: "horizontal",
                        scaleID: "y",
                        value: 100,
                        borderWidth: 3,
                        borderColor: "darkgray",
                        drawTime: "beforeDatasetsDraw"
                    },
                    {
                        type: "line",
                        mode: "vertical",
                        scaleID: "x",
                        value: yearIndex,
                        borderColor: "gray",
                        drawTime: "afterDraw",
                        label: {
                            content: new Date().getFullYear(),
                            display: true,
                            position: "start"
                        }
                    }]
                }
            },
            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
                enabled: false
            },
            scales: {
                y: {
                    ticks: {
                        callback: function (value: number) {
                            return value + "%";
                        }
                    }
                }
            }
        });

        setChartData({
            labels: (source as IChartResponse).fundPrices.map(x => x.date),
            datasets: [
                {
                    data: (source as IChartResponse).fundPrices.map(x => x.price),
                    label: (source as IChartResponse).name,
                    borderColor: "#c45850",
                    fill: false,
                    radius: 0,
                    // @ts-expect-error TypeScript not yet compatible
                    lineTension: 0.4
                }, {
                    data: (target as IChartResponse).fundPrices.map(x => x.price),
                    label: (target as IChartResponse).name,
                    borderColor: "#3e95cd",
                    fill: false,
                    radius: 0,
                    // @ts-expect-error TypeScript not yet compatible
                    lineTension: 0.4
                }
            ]
        });
    };



    /**
    * Close chart
    */
    const onClose = (e?: MouseEvent) => {
        if (e) e.stopPropagation();
        isInitialMount.current = true;
        onChartClose();
    };


    useEffect(() => {
        if (!fundId) return;
        isInitialMount.current = false;

        if (fundId && !compareFundId) {
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_SERVICE_HOST}/v1/reports/chart/${fundId}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get("sid")}`
                }
            })
                .then(response => {
                    renderSingleFundChart(response.data.data as IChartResponse);
                })
                .catch(() => {
                    onClose();
                });
        }
        else if (fundId && compareFundId) {
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_SERVICE_HOST}/v1/reports/chart/${fundId}/${compareFundId}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get("sid")}`
                }
            })
                .then(response => {
                    console.log("Loaded multi fund prices", fundId, compareFundId);
                    renderComparisonFundChart(response.data.data as IChartResponse[]);
                })
                .catch(() => {
                    onClose();
                });
        }

    }, [fundId, compareFundId]);


    return (
        <div id="FundChart" className={visibility} onMouseDown={onClose}>
            {!isInitialMount.current && chartOption && chartData && (
                <ReactChart
                    type="line"
                    data={chartData}
                    // @ts-expect-error TypeScript not yet compatible
                    options={chartOption}
                />
            )}
        </div>
    );

};

export default FundChart;
