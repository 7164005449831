import React, { useEffect, useState, useRef } from "react";
import "./Income.css";
import axios, { AxiosRequestConfig } from "axios";
import FundList from "../../components/FundList/FundList";
import ETagCache from "../../services/ETagCache";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import PersistScrollPosition from "../../services/PersistScrollPosition";
import { TChartShowClick } from "../../Router";

interface IIncomeProps {
    onChartShow: TChartShowClick;
}


const Income = (props: IIncomeProps) => {
    const history = useNavigate();
    const [requiredYield, setRequiredYield] = useState(5);
    const [funds, setFunds] = useState([]);

    const pathname = window.location.pathname;

    const onButtonUp = () => {
        if (requiredYield < 10) setRequiredYield(requiredYield + 1);
        sessionStorage.setItem(pathname, "0");                                                                              // Reset scrolling to top
    };


    const onButtonDown = () => {
        if (requiredYield > 1) setRequiredYield(requiredYield - 1);
        sessionStorage.setItem(pathname, "0");                                                                              // Reset scrolling to top
    };

    const updateScrollPosition = useRef(false);                                                                             // Handle scroll reset
    const isInitialMount = useRef(true);

    if (isInitialMount.current) {                                                                                           // Reset required yield
        isInitialMount.current = false;
        const sessionYield: string | null = sessionStorage.getItem(`${pathname}-yield`);                                    // Got stored session yield?
        if (sessionYield) setRequiredYield(+sessionYield);                                                                  // Set as new requiredYield
    }


    useEffect(() => {

        ETagCache(axios as AxiosRequestConfig)({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            },
            data: {
                query: `{
                        funds(requiredYield: ${requiredYield}) {
                            _id
                            lastUpdated
                            name
                            isin
                            yield
                            tradeSignal
                            dividendFrequency
                            fundRating
                            fundSizeInMillions
                            moneyFlow
                            isRolling52WeekHigh
                            ytd
                            netChangePercent
                            ongoingCharge
                            ratings {
                                morningstarRating
                            }
                            category {
                                name
                                ytd
                                rating
                            }
                            trailingPerformance {
                                M12
                                M36
                                M60
                            }
                            trailingStandardDeviation {
                                M12
                                M36
                                M60
                            }
                            medalistRating
                        }
                }`
            }
        })
            .then(response => {
                updateScrollPosition.current = true;
                sessionStorage.setItem(`${pathname}-yield`, requiredYield.toString());                                      // Store selected yield
                setFunds(response.data.data.funds);
            })
            .catch(() => {
                history("/login");
            });

    }, [requiredYield]);

    return (
        <div id="pagewrapper" className="requiredYield">
            <div id="yield">
                <div className="shadow">
                    <span className="labelYield">Required Annual Yield</span>
                    <img className="button" alt="" src="/images/down.png" onClick={onButtonDown} />
                    <div className="requiredYield">{requiredYield}%</div>
                    <img className="button" alt="" src="/images/up.png" onClick={onButtonUp} />
                </div >
            </div>

            <h2>Funds</h2>
            <FundList funds={funds} onChartShow={props.onChartShow} />
            <PersistScrollPosition updateScrollPosition={updateScrollPosition.current} />
        </div>
    );

};

export default Income;
