import React, { MouseEvent } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Menu.css";
import Cookies from "js-cookie";
import { TMenuToggleClick } from "../../Router";



export interface IMenuProps {
    menuVisibility: boolean;
    onMenuToggle: TMenuToggleClick;
}


const Menu = (props: IMenuProps) => {
    const { pathname } = useLocation();                                                                                     // Get path name

    const visibility: string = props.menuVisibility ? "show" : "hide";


    // Logout
    const onLogOut = (e: MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        Cookies.remove("sid");
    };


    // Menu link clicked
    const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sessionStorage.removeItem((e.target as any).pathname);                                                              // Delete scroll position
        window.scrollTo(0, 0);                                                                                              // Scroll to top position
        props.onMenuToggle();
    };


    return (
        <nav id="flyoutMenu" className={visibility}>
            <ul>
                <li className={pathname === "/" ? "active" : ""}>
                    <Link to="/" onClick={onClick}>Portfolio</Link>
                </li>
                <li className={pathname === "/categoryratings" ? "active" : ""}>
                    <Link to="/categoryratings" onClick={onClick}>Category Indexes</Link>
                </li>
                {/* <li className={pathname === "/benchmarkratings" ? "active" : ""}>
                    <Link to="/benchmarkratings" onClick={onClick}>Benchmark Indexes</Link>
                </li> */}
                <li className={pathname === "/search" ? "active" : ""}>
                    <Link to="/search" onClick={onClick}>Search Funds</Link>
                </li>
                <li className={pathname === "/editportfolio" ? "active" : ""}>
                    <Link to="/editportfolio" onClick={onClick}>Edit Portfolio</Link>
                </li>
                <li className={pathname === "/income" ? "active" : ""}>
                    <Link to="/income" onClick={onClick}>Income Funds</Link>
                </li>
                <li className={pathname === "/lowrisk" ? "active" : ""}>
                    <Link to="/lowrisk" onClick={onClick}>Select Funds</Link>
                </li>
                <li className={pathname === "/momentum" ? "active" : ""}>
                    <Link to="/momentum" onClick={onClick}>Trailing Return</Link>
                </li>
                <li className={pathname === "/info" ? "active" : ""}>
                    <Link to="/info" onClick={onClick}>Information</Link>
                </li>
                <li className={pathname === "/status" ? "active" : ""}>
                    <Link to="/status" onClick={onClick}>Service Logs</Link>
                </li>
                <li>
                    <hr></hr>
                </li>
                <li>
                    <Link to="/login" onMouseDown={onLogOut} onClick={onClick}>Logout</Link>
                </li>
            </ul>
        </nav>
    );

};

export default Menu;
