import React from "react";
import { IMomentumBenchmark } from "../../pages/Momentum/Momentum";
import { TChartShowClick } from "../../Router";
import MomentumItem from "../MomentumItem/MomentumItem";


interface IMomentumListProps {
    trendingBenchmarks: IMomentumBenchmark[];
    onChartShow: TChartShowClick;
}


const MomentumList = (props: IMomentumListProps) => {
    const { trendingBenchmarks } = props;

    return (
        <table id="table">
            <tbody>
                {trendingBenchmarks.map((benchmark: IMomentumBenchmark) => (
                    <MomentumItem key={benchmark.morningstarCategory} benchmark={benchmark} onChartShow={props.onChartShow} />
                ))}
            </tbody>
        </table>
    );

};

export default MomentumList;
