import axios, { AxiosHeaderValue, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import md5 from "md5";

type THeader = { [name: string]: AxiosHeaderValue | undefined; };


/**
 * Returns true if the request is cachable
 * 
 * @param  {AxiosRequestConfig} config
 */
const isCacheableMethod = (config: AxiosRequestConfig) => {
    return !!(["GET", "HEAD", "POST"].indexOf((config.method || "").toUpperCase()) + 1);
};



/**
 * Creates a UUID based on url + payload
 * 
 * @param  {AxiosRequestConfig} config
 * @returns string
 */
const getUUIDByAxiosConfig = (config: AxiosRequestConfig): string => {
    let uuid: string = config.url as string;
    if ((config.method || "").toLowerCase() === "post") {                                                                   // GraphQL would have the same url, but different payload
        if (typeof config.data === "string") {
            uuid += config.data;
        }
        else uuid += JSON.stringify(config.data);
    }

    return md5(uuid);                                                                                                       // Create hash
};



/**
 * Handle intercepted request
 * 
 * @param  {AxiosRequestConfig} config
 * @returns AxiosRequestConfig
 */
const requestInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (isCacheableMethod(config)) {
        const uuid: string = getUUIDByAxiosConfig(config);                                                                  // Get unique identifier
        const lastCachedResult = sessionStorage.getItem(uuid + "etag");                                                     // Get etag of last cached response

        if (lastCachedResult) config.headers.set("If-None-Match", lastCachedResult);                                        // Add header with etag
    }

    return config;
};



/**
 * Get etag value from header
 * 
 * @param  {string} headerName
 * @param  {THeader={}} headers
 * @returns string
 */
const getHeaderCaseInsensitive = (headerName: string, headers: THeader = {}): string => {
    headerName = headerName.toLowerCase();
    let index: string = "";
    for (const property in headers) if (headerName === property.toLowerCase()) index = property;
    return headers[index] as string;
};



/**
 * Cache response (200)
 * 
 * @param  {AxiosResponse} response
 */
const responseInterceptor = (response: AxiosResponse) => {
    if (isCacheableMethod(response.config)) {
        const responseETag = getHeaderCaseInsensitive("etag", response.headers);                                            // Get ETag from header
        if (responseETag) {
            const uuid = getUUIDByAxiosConfig(response.config);                                                             // Get identifier for query
            sessionStorage.setItem(uuid + "etag", responseETag);                                                            // Store etag for uuid
            sessionStorage.setItem(uuid + "value", JSON.stringify(response.data));                                          // Store response
        }
    }

    return response;
};



/**
 * Return response from cache (304)
 * 
 * @param  {any} error
 */
// eslint-disable-next-line
const responseErrorInterceptor = (error: any) => {
    if (error.response && error.response.status === 304) {                                                                  // 304 returned
        const uuid = getUUIDByAxiosConfig(error.response.config);                                                           // Get unique identifier
        const getCachedResult = JSON.parse(sessionStorage.getItem(uuid + "value") as string);
        if (!getCachedResult) return Promise.reject(error);

        console.log("Returned data from cache");

        const newResponse = error.response;                                                                                 // Convert error to 200
        newResponse.status = 200;
        newResponse.data = getCachedResult;                                                                                 // Return cache

        return Promise.resolve(newResponse);
    }
    else return Promise.reject(error);
};



/**
 * Default module function
 * 
 * @param  {AxiosRequestConfig} config
 */
export default function axiosETAGCache(config: AxiosRequestConfig) {
    const instance = axios.create(config);
    instance.interceptors.request.use(requestInterceptor);
    instance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

    return instance;
}
