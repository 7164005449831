import React from "react";
import { IStatusItem } from "../../pages/Status/Status";
import StatusItem from "../StatusItem/StatusItem";

interface IStatusListProps {
    statusRecords: IStatusItem[];
}


const StatusList = (props: IStatusListProps) => {
    const { statusRecords } = props;

    let keys = 0;

    return (
        <table id="table">
            <tbody>
                {statusRecords.map((record: IStatusItem) => (
                    <StatusItem key={keys++} record={record} />
                ))}
            </tbody>
        </table>
    );

};

export default StatusList;
