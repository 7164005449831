import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const SecureRoutes = () => {

    /*
     * Return true if a JWT token is found
    */
    const isLoggedIn = (): boolean => {
        return !!Cookies.get("sid");
    };

    return (
        isLoggedIn() ? <Outlet /> : <Navigate to="/login" />
    );
};

export default SecureRoutes;
