import React from "react";
import Router from "./Router";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.querySelector("#container") as HTMLElement);
if (process.env.REACT_APP_ENV === "local") {
    root.render(
        <Router />
    );
}
else {
    root.render(
        <React.StrictMode>
            <Router />
        </React.StrictMode>
    );
}


