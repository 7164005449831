import { useLocation } from "react-router-dom";
import "./Header.css";
import React from "react";
import { TMenuToggleClick } from "../../Router";


export interface IHeaderProps {
    menuVisibility: boolean;
    onMenuToggle: TMenuToggleClick;
}


const Header = (props: IHeaderProps) => {

    const { pathname } = useLocation();                                                                                     // Get path name

    let visibility = "hide";

    if (props.menuVisibility) {
        visibility = "show";
    }

    const pageTitles = {
        "/search": "Search Funds",
        "/category/": "Category Funds YTD",
        "/categoryratings": "Category Indexes YTD",
        "/editportfolio": "Edit Portfolio",
        "/income": "Income Funds",
        "/lowrisk": "Select Funds",
        "/info": "Information",
        "/status": "Service Logs",
        "/login": "Login",
        "/resetpassword/": "Reset Password",
        "/signup": "Sign Up",
        "/momentum": "Trailing Return (1 Year)",
        "/": "Portfolio"
    };


    const getTitleForPage = (pathname: string) => {
        for (const [key, value] of Object.entries(pageTitles)) {
            if (pathname.startsWith(key)) return value;
        }

        return "Unknown";
    };

    const headerTitle = getTitleForPage(pathname);

    return (
        <>
            <div id="overlay" onClick={props.onMenuToggle} className={visibility} />
            <div id="header">
                <div id="title">
                    <span>Fund Analyzer</span>
                    <img alt="" src="/images/menu.png" onMouseDown={props.onMenuToggle} />
                </div>
                <div id="subtitle">{headerTitle}</div>
            </div>
        </>
    );

};

export default Header;
