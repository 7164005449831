
// Returns expiry in days from JWT token
const getExpiryFromToken = (token: string): number => {
    const base64Url: string = token.split(".")[1];                                                                          // Decode JWT token
    const base64: string = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));
    const claims = JSON.parse(jsonPayload);
    return (claims.exp - claims.iat) / 86400;                                                                               // Get claim expiry in days
};


export { getExpiryFromToken };