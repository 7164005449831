import React from "react";
import "./Info.css";

const Info = () => {

    return (
        <div id="pagewrapper" className="info">
            FundAnalyzer monitors nightly 5200+ managed funds and ETF&apos;s available by Fidelity. The data and various technical indicators are obtained from
            Morningstar and Fidelity.

            <h1 >Portfolio</h1>
            The portfolio home page displays the funds selected in the Edit Portfolio menu.

            <h1 >Category Indexes</h1>
            The categories are ordered by the average return of the funds in the category.
            The rating is similar to the Morningstar Ratings, which are based on a bell curve distribution: 10% receive a
            5-star rating, 22.5% receive a 4-star rating, 35% receive a 3-star rating, 22.5% receive a 2-star rating and
            10% receive a 1-star rating.
            The Best Fund is the best performing fund in the category.

            <h1 >Search Funds</h1>
            Search all available funds by a (part) match on the search criteria.

            <h1 >Edit Portfolio</h1>
            Manage the funds in the Portfolio.

            <h1 >Income Funds</h1>
            Display the top income funds for the required yearly yield, with an emphasis on preserving capital.<br />
            The Income funds are filtered on the 12 months yield +/- 0.5%; have a low Morningstar Risk rating and high Morningstar Return rating.
            The resulting Income Funds are ordered in decending order on (return - ongoing charge).
            The resulting funds provide the minimum required yield, low price variation (Morningstar Risk), high returns (Morningstar Return) and best capital growth based on the last year of data.
            <h3 >Morningstar Risk ranking</h3>
            The Morningstar Risk ranking is based on variations in a fund&apos;s monthly returns - with an emphasis on downside
            variations - as compared to similar funds within the same category.
            <h3 >Morningstar Return ranking</h3>
            The Morningstar Return rating is a measure of a fund&apos;s risk-adjusted return,
            relative to similar funds in the same category.

            <h1 >Select Funds</h1>
            These funds represents the highest returns with the least amount of variation based on Morningstar&apos;s Risk Adjusted Return
            (A &quot;risk penalty&quot; is subtracted from each investment&apos;s total return, based on the variation in its month-to-month return during the rating period,
            with an emphasis on downward variation. The greater the variation, the larger the penalty).<br />

            The funds are then sorted in descending order based on the Risk Adjusted Return, and reduced to Gold Morningstar Medalist Rating only
            (The Morningstar Medalist Rating for funds is the summary expression of forward-looking analysis of investment strategies)

            <h1 >Trailing Return (1 Year)</h1>
            Provides insight into the Benchmark Index return momentum over the trailing 12 months.
            For each fund the return is calculated and averaged for the Benchmark Index.
            The Benchmark Index is then ordered in decending order and assigned a rating.
            The up/down arrow indicates if the Benchmark Index has moved up or down the ranking.
            The rating is similar to the Morningstar Ratings, which are based on a bell curve distribution: 10% receive a
            5-star rating, 22.5% receive a 4-star rating, 35% receive a 3-star rating, 22.5% receive a 2-star rating and
            10% receive a 1-star rating.
            The <b>Best Fund</b> is the best performing fund in the Benchmark Index.

            <h1 >Fund Statistics</h1>

            <h3>Name</h3>
            Legal name of the fund.

            <h3 >ISIN</h3>
            ISIN is the acronym for International Securities Identification Number. ISIN numbers are the unique 12-digit numbers that are recognized by the International Standards Organization, located in Geneva, Switzerland, as security identifiers for cross-border securities transactions.<br />

            <h3 >Trend</h3>
            The trend is upwards when the price is above the 200 day exponential moving average (EMA).

            <h3 >Date</h3>
            Date of the last price update.

            <h3 >Fund YTD</h3>
            Consists of the Fund Rating, chart button and fund performance year to date (YTD).
            The Fund Rating is calculated by sorting all fund returns by YTD in descending order.
            The rating is similar to the Morningstar Ratings, which are based on a bell curve distribution: 10% receive a
            5-star rating, 22.5% receive a 4-star rating, 35% receive a 3-star rating, 22.5% receive a 2-star rating and
            10% receive a 1-star rating.

            <h3 >Category</h3>
            Funds are grouped into categories according to their actual investment style, not merely their stated investment objectives,
            nor their ability to generate a certain level of income. To ensure homogeneous groupings, Morningstar normally allocates
            funds to categories on the basis of their portfolio holdings. Several portfolios are taken into account to ensure that
            the fund´s real investment stance is taken into account.

            <h3 >Benchmark</h3>
            A best-fit index is the market index with the highest correlation to a fund over a given period, typically three years.

            <h3 >Category YTD</h3>
            Displays the Category rating and Category return YTD.
            The Category Rating is calculated from the mean average YTD of the funds within the category.
            The applied ratings are similar to the Morningstar Ratings,
            which are based on a bell curve distribution: the top 10% receive a 5-star rating, 22.5% receive a 4-star
            rating, 35% receive a 3-star rating, 22.5% receive a 2-star rating and 10% receive a 1-star rating.

            <h3>Benchmark YTD</h3>
            Benchmark Index rating and Benchmark return YTD.
            The Benchmark Index rating is calculated from the mean average YTD of the funds within the benchmark.
            The applied ratings are similar to the Morningstar Ratings,
            which are based on a bell curve distribution: the top 10% receive a 5-star rating, 22.5% receive a 4-star
            rating, 35% receive a 3-star rating, 22.5% receive a
            2-star rating and 10% receive a 1-star rating.

            <h3>Morningstar</h3>
            The Morningstar rating (3 year) is a measure of a fund&apos;s historic risk-adjusted return, relative to similar funds. Funds are
            rated from 1 to 5 stars, with the best performers receiving 5 stars and the worst performers receiving a single
            star.<br />
            <br />
            Morningstar gauges a fund&apos;s risk by calculating a risk penalty for each fund based on &quot;expected utility
            theory,&quot; a commonly used method of economic analysis. It assumes that investors are more concerned about a
            possible poor outcome than an unexpectedly good outcome, and those investors are willing to give up a small
            portion of an investment&apos;s expected return in exchange for greater certainty.<br />
            In general, a Morningstar rating of 5 is considered undervalued and a rating of 1 is overvalued.

            <h3>MS Medalist</h3>
            The Medalist Rating is Morningstar&apos;s forward-looking, analyst- and quantitatively-driven ratings system that is expressed with Gold,
            Silver, Bronze, Neutral, and Negative indicators. The Medalist Rating unifies Morningstar analyst insights and processes with scalable machine learning models.
            Medalist Ratings that are  directly assigned by an Analyst are typically re-evaluated on an annual basis,
            while ratings that are indirectly assigned by an Analyst, or assigned algorithmically, are produced monthly.
            Top rated medalist funds have proven to consistently outperform funds in the same category.

            <h3>Yield</h3>
            The rate of return, expressed as a percentage, paid on an investment - in the form of dividends for stocks and funds or the coupon rate for bonds. Yield should not be confused with Total Return.

            <h3>Ongoing Cost</h3>
            The Ongoing Charge represents the costs you can reasonably expect to pay as an investor from one year to the next, under normal circumstances.

            <h3>Fund Size</h3>
            Fund size is the total net assets in a mutual fund or ETF across all share classes.

            <h3 >Standard Deviation</h3>
            The standard deviation over the trailing 12-month is the statistical measurement of dispersion about an average, which depicts how widely a stock or portfolio&apos;s
            returns varied over a certain period of time. Investors use the standard deviation of historical performance to try to predict the range of returns that is most likely for a given investment.
            When a stock or portfolio has a high standard deviation, the predicted range of performance is wide, implying greater volatility.

            <h3 >Return</h3>
            Represents the total return over the trailing 12-months.

            <h3 >Better Fund (Portfolio)</h3>
            A better fund advice is displayed when a fund performs a minimum of 5% better than the owned fund
            within the same Benchmark Index.

        </div>

    );

};

export default Info;
