import React, { useEffect, useState } from "react";
import axios from "axios";
import StatusList from "../../components/StatusList/StatusList";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";


export interface IStatusItem {
    type: string;
    source: string;
    dateCreated: string;
    message: string;
}



const Status = () => {
    const history = useNavigate();
    const [statusRecords, setStatusRecords] = useState([]);


    useEffect(() => {

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            },
            data: {
                query: `{
                        status {
                            type
                            source
                            dateCreated
                            message
                        }
                    }`
            }
        })
            .then(response => {
                console.log("Loaded status logs", response.data.data.status.length);
                setStatusRecords(response.data.data.status);
            })
            .catch(() => {
                history("/login");
            });

    }, []);

    return (
        <div id="pagewrapper">
            <h2>Status Records</h2>
            <StatusList statusRecords={statusRecords} />
        </div>
    );

};

export default Status;
