import React, { useState } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import FundChart from "./components/FundChart/FundChart";

import "./css/Router.css";
import "./css/table.css";

import Portfolio from "./pages/Portfolio/Portfolio";
import CategoryRatings from "./pages/CategoryRatings/CategoryRatings";
import Category from "./pages/Category/Category";
import Search from "./pages/Search/Search";
import EditPortfolio from "./pages/EditPortfolio/EditPortfolio";
import Income from "./pages/Income/Income";
import Info from "./pages/Info/Info";
import Status from "./pages/Status/Status";
import LowRiskFunds from "./pages/LowRiskFunds/LowRiskFunds";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import SignUp from "./pages/SignUp/SignUp";
import Momentum from "./pages/Momentum/Momentum";
import SecureRoutes from "./services/SecureRoutes";


export interface IChartShow {
    fundId: string;
    compareFundId?: string;
}

export type TChartShowClick = (fundId: string, compareFundId?: string) => void;
export type TChartCloseClick = () => void;
export type TMenuToggleClick = () => void;


const Router = () => {

    const [visible, setVisible] = useState(false);                                                                          // Show slide menu
    const [chartFunds, setChartFunds] = useState({ fundId: "", compareFundId: "" });

    // Toggle menu visibility
    const onMenuToggle = () => {                                                                                            // Show/hide slide menu
        setVisible(!visible);
    };

    const onChartClose = () => {
        setChartFunds({ fundId: "", compareFundId: "" });
    };

    const onChartShow = (fundId: string, compareFundId: string = "") => {
        setChartFunds({ fundId, compareFundId });
    };

    return (
        <BrowserRouter>
            <Header onMenuToggle={onMenuToggle} menuVisibility={visible} />
            <Menu onMenuToggle={onMenuToggle} menuVisibility={visible} />
            <FundChart onChartShow={chartFunds} onChartClose={onChartClose} />

            <Routes>
                <Route element={<SecureRoutes />}>
                    <Route element={<Portfolio onChartShow={onChartShow} />} path="/" />
                    <Route element={<Momentum onChartShow={onChartShow} />} path="/momentum" />
                    <Route element={<CategoryRatings onChartShow={onChartShow} />} path="/categoryratings" />
                    <Route element={<Category onChartShow={onChartShow} />} path="/category/:category" />
                    <Route element={<Search onChartShow={onChartShow} />} path="/search" />
                    <Route element={<EditPortfolio />} path="/editportfolio" />
                    <Route element={<Income onChartShow={onChartShow} />} path="/income" />
                    <Route element={<LowRiskFunds onChartShow={onChartShow} />} path="/lowrisk" />
                    <Route element={<Info />} path="/info" />
                    <Route element={<Status />} path="/status" />
                </Route>

                <Route element={<Login />} path="/login" />
                <Route element={<ResetPassword />} path="/resetpassword/:resetToken" />
                <Route element={<SignUp />} path="/signup" />

                <Route
                    element={<Navigate to="/" replace />}
                    path="*"
                />
            </Routes>

        </BrowserRouter>
    );

};

export default Router;
