import { ICategoryRating } from "../../pages/CategoryRatings/CategoryRatings";
import { TChartShowClick } from "../../Router";
import CategoryRatingItem from "../CategoryRatingItem/CategoryRatingItem";
import React from "react";


export interface ICategoryRatingListProps {
    categoryRatings: ICategoryRating[];
    onChartShow: TChartShowClick;
}


const CategoryRatingList = (props: ICategoryRatingListProps) => {

    return (
        <table id="table">
            <tbody>
                {props.categoryRatings.map(category => (
                    <CategoryRatingItem key={category.name} category={category} onChartShow={props.onChartShow} />
                ))}
            </tbody>
        </table>
    );

};

export default CategoryRatingList;
