import merge from "lodash.merge";

interface IOwaspConfig {
    allowPassphrases?: boolean;
    maxLength?: number;
    minLength?: number;
    minPhraseLength?: number;
    minOptionalTestsToPass?: number;
}


interface IOwaspDefault {
    allowPassphrases: boolean;
    maxLength: number;
    minLength: number;
    minPhraseLength: number;
    minOptionalTestsToPass: number;
}



export interface IOwaspResult {
    errors: string[];
    failedTests: number[];
    passedTests: number[];
    requiredTestErrors: string[];
    optionalTestErrors: string[];
    isPassphrase: boolean;
    strong: boolean;
    optionalTestsPassed: number;
}

type TTest = ((password: string) => string | undefined);



export class Owasp {

    private configs: IOwaspDefault;


    /**
     * Constructor
     * 
     * @param  {IOwaspConfig} config
     */
    constructor(config: IOwaspConfig) {
        const defaultConfig: IOwaspConfig = {                                                                               // Default config
            allowPassphrases: true,
            maxLength: 128,
            minLength: 10,
            minPhraseLength: 20,
            minOptionalTestsToPass: 4
        };
        this.configs = merge(defaultConfig, config) as IOwaspDefault;
    }


    private required: TTest[] = [

        (password: string) => {                                                                                             // enforce a minimum length
            if (password.length < this.configs.minLength) {
                return "The password must be at least " + this.configs.minLength + " characters long.";
            }
        },

        (password: string) => {                                                                                             // enforce a maximum length
            if (password.length > this.configs.maxLength) {
                return "The password must be fewer than " + this.configs.maxLength + " characters.";
            }
        },

        (password: string) => {                                                                                             // forbid repeating characters
            if (/(.)\1{2,}/.test(password)) {
                return "The password may not contain sequences of three or more repeated characters.";
            }
        }
    ];



    private optional: TTest[] = [

        // require at least one lowercase letter
        (password: string) => {
            if (!/[a-z]/.test(password)) {
                return "The password must contain at least one lowercase letter.";
            }
        },

        // require at least one uppercase letter
        (password: string) => {
            if (!/[A-Z]/.test(password)) {
                return "The password must contain at least one uppercase letter.";
            }
        },

        // require at least one number
        (password: string) => {
            if (!/[0-9]/.test(password)) {
                return "The password must contain at least one number.";
            }
        },

        // require at least one special character
        (password: string) => {
            if (!/[^A-Za-z0-9]/.test(password)) {
                return "The password must contain at least one special character.";
            }
        }
    ];



    /**
     * Test password
     * 
     * @param  {string} password
     * @returns string[]
     */
    public test(password: string): IOwaspResult {

        const result: IOwaspResult = {                                                                                      // create an object to store the test results
            errors: [],
            failedTests: [],
            passedTests: [],
            requiredTestErrors: [],
            optionalTestErrors: [],
            isPassphrase: false,
            strong: true,
            optionalTestsPassed: 0
        };

        let i: number = 0;                                                                                                  // Always submit the password/passphrase to the required tests
        this.required.forEach((test: TTest) => {
            const error: string = test(password) as string;
            if (error) {
                result.strong = false;
                result.errors.push(error);
                result.requiredTestErrors.push(error);
                result.failedTests.push(i);
            }
            else result.passedTests.push(i);
            i++;
        });

        if (
            this.configs.allowPassphrases === true &&
            password.length >= this.configs.minPhraseLength
        ) {
            result.isPassphrase = true;
        }

        if (!result.isPassphrase) {
            let j: number = this.required.length;
            this.optional.forEach((test: TTest) => {
                const error: string = test(password) as string;
                if (error) {
                    result.errors.push(error);
                    result.optionalTestErrors.push(error);
                    result.failedTests.push(j);
                }
                else {
                    result.optionalTestsPassed++;
                    result.passedTests.push(j);
                }
                j++;
            });
        }

        if (                                                                                                                // If the password is not a passphrase, assert that it has passed a sufficient number of the optional tests, per the configuration
            !result.isPassphrase &&
            result.optionalTestsPassed < this.configs.minOptionalTestsToPass
        ) {
            result.strong = false;
        }

        return result;
    }

}