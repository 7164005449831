import React, { useEffect, useState, useRef } from "react";
import axios, { AxiosRequestConfig } from "axios";
import MomentumList from "../../components/MomentumList/MomentumList";
import ETagCache from "../../services/ETagCache";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import PersistScrollPosition from "../../services/PersistScrollPosition";
import { TChartShowClick } from "../../Router";

export interface IMomentumBenchmark {
    morningstarCategory: string;
    rating: number;
    percentage: number;
    fundsInCategory: number;
    rankingPosition: "UP" | "DOWN";
    bestFund: {
        _id: string;
        name: string;
        ytd: number;
    };
}


interface ITrendingBenchmarksProps {
    onChartShow: TChartShowClick;
}


const Momentum = (props: ITrendingBenchmarksProps) => {
    const [trendingBenchmarks, setTrendingBenchmarks] = useState([]);
    const updateScrollPosition = useRef(false);
    const history = useNavigate();


    useEffect(() => {

        ETagCache(axios as AxiosRequestConfig)({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            },
            data: {
                query: `{
                    trending {
                        morningstarCategory
                        rating
                        percentage
                        fundsInCategory
                        rankingPosition
                        bestFund {
                            _id
                            name
                        }
                    }
                }`
            }
        })
            .then(response => {
                console.log("Loaded momentum categories", response.data.data.trending.length);
                updateScrollPosition.current = true;
                setTrendingBenchmarks(response.data.data.trending);
            })
            .catch(() => {
                history("/login");
            });

    }, []);

    return (
        <div id="pagewrapper">
            <h2>Category Indexes</h2>
            <MomentumList trendingBenchmarks={trendingBenchmarks} onChartShow={props.onChartShow} />
            <PersistScrollPosition updateScrollPosition={updateScrollPosition.current} />
        </div>
    );

};

export default Momentum;
