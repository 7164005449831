import { IStatusItem } from "../../pages/Status/Status";
import React from "react";

interface IStatusItemProps {
    record: IStatusItem;
}


const StatusItem = (props: IStatusItemProps) => {

    const { record: { type, source, dateCreated, message } } = props;

    return (
        <>
            <tr>
                <th colSpan={2}>
                    {type}
                </th>
            </tr>

            <tr>
                <td className="key">
                    Process
                </td>
                <td className="value">
                    {source}
                </td>
            </tr>

            <tr>
                <td className="key">
                    Date
                </td>
                <td className="value">
                    {dateCreated}
                </td>
            </tr>

            <tr>
                <td className="key">
                    Status
                </td>
                <td className="value">
                    {message}
                </td>
            </tr>

        </>
    );

};

export default StatusItem;
