import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { IOwaspResult, Owasp } from "../../services/owasp";
import { getExpiryFromToken } from "../../services/Utils";

const SignUp = () => {

    const [formFields, setFormFields] = useState({
        email: "",
        password: "",
        repeatpassword: ""
    });
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [rememberEmail, setRememberEmail] = useState(true);
    const [loginSuccessful, setLoginSuccessful] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [invalidAccount, setInvalidAccount] = useState(false);

    useEffect(() => {

        // Enable submit button
        const enableButton = () => {
            let isValid = true;

            if (!isEmail(formFields.email)) isValid = false;
            if (!formFields.password) isValid = false;                                                                      // Errors are already displayed
            if (isValidPassword(formFields.password)) isValid = false;
            if (!formFields.repeatpassword) isValid = false;
            if (formFields.password !== formFields.repeatpassword) isValid = false;

            setButtonEnabled(isValid);
        };

        setEmailError(formFields.email && !isEmail(formFields.email) ? "Please enter a valid email" : "");

        let pwdError = "";
        if (formFields.password) pwdError = isValidPassword(formFields.password);
        if (!pwdError && formFields.repeatpassword && formFields.password !== formFields.repeatpassword) pwdError = "Password does not match";
        setPasswordError(pwdError);

        if (invalidAccount) setEmailError("Account already exists");

        enableButton();
    }, [formFields.email, formFields.password, formFields.repeatpassword, emailError, invalidAccount, passwordError]);  // , repeatPasswordError , passwordError



    // Is email valid?
    const isEmail = (email: string) => {
        const regex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return regex.test(email);
    };


    // Validate OWASP password strength
    const isValidPassword = (password: string) => {
        const owasp: Owasp = new Owasp({
            maxLength: 30,
            minLength: 8
        });

        const result: IOwaspResult = owasp.test(password);
        if (result.errors.length) {                                                                                         // Return first returned error
            return result.errors[0];
        }
        else return "";
    };


    // Update state of email/password.
    // Note: uses field "id"
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormFields({
            ...formFields,
            [e.target.id]: e.target.value,
        });

        if (e.target.id === "email") setInvalidAccount(false);
    };


    // Toggle remember email checkbox
    const onChangeRememberEmail = () => {
        setRememberEmail(!rememberEmail);
    };


    // Handle submit
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/v1/authentication/register`,
            data: {
                email: formFields.email,
                password: formFields.password
            }
        })
            .then(response => {
                const expires: number = getExpiryFromToken(response.data.data.token);                                       // Get expiry
                Cookies.set("sid", response.data.data.token, { expires });                                                  // Set token
                if (rememberEmail) Cookies.set("email", formFields.email);                                                  // Set cookie
                setLoginSuccessful(true);                                                                                   // Redirect to portfolio page
            })
            .catch(() => {
                setInvalidAccount(true);
            });
    };

    if (loginSuccessful) return <Navigate to={"/"} />;

    return (
        <div id="pagewrapper" className="login-container">

            <form id="resetform" onSubmit={handleSubmit}>
                <input id="email" className="loginInput emailInput" tabIndex={1} type="text" placeholder="email" onChange={onChange} autoComplete="username" required />
                <div className="text-danger">{emailError}</div>

                <input id="password" className="loginInput" tabIndex={2} type="password" onChange={onChange} placeholder="password"
                    required autoComplete="new-password" />
                <input id="repeatpassword" className="loginInput" tabIndex={3} type="password" onChange={onChange} required placeholder="re-type password" autoComplete="new-password" />

                <input type="checkbox" checked={rememberEmail} onChange={onChangeRememberEmail} />
                <span>Remember me</span>
                <div className="text-danger">{passwordError}</div>

                <button id="submit" className="submitButton" tabIndex={4} type="submit" disabled={!buttonEnabled}>Sign Up</button>
            </form>

        </div>
    );
};

export default SignUp;
