import React, { MouseEvent } from "react";
import { ICategoryRating } from "../../pages/CategoryRatings/CategoryRatings";
import { TChartShowClick } from "../../Router";
import { StoreScrollPosition } from "../../services/PersistScrollPosition";
import { useNavigate } from "react-router-dom";


interface ICategoryRatingItemProps {
    category: ICategoryRating;
    onChartShow: TChartShowClick;
}



const CategoryRatingItem = (props: ICategoryRatingItemProps) => {
    const { category } = props;
    const history = useNavigate();


    const showCategoryFunds = (category: string) => {                                                                       // Redirect to category fund list
        StoreScrollPosition();
        history("/category/" + encodeURIComponent(category));
    };


    const showChart = (e: MouseEvent, fundId: string) => {
        e.stopPropagation();
        console.log("show chart " + fundId);
        props.onChartShow(fundId);
    };


    const renderBestFund = () => {
        if (category.bestFund) {
            return (
                <tr>
                    <td className="key">Best Fund</td>
                    <td className="value">
                        <p>
                            <img alt="" onClick={e => showChart(e, category.bestFund._id)} src="/images/graph.png" />{category.bestFund.name} [{category.bestFund.ytd.toFixed(2)}%]
                        </p>
                    </td>
                </tr>
            );
        }
        else return;
    };



    /**
     * Money flow
     * @returns 
     */
    const moneyFlow = () => {
        if (category.moneyFlow === null) return;
        if (category.moneyFlow > 0) {                                                                                 // > 0%
            return (
                <span className='price-up'>+{category.moneyFlow.toFixed(2)}%<img alt='' src='/images/uparrowgreen.png' /></span>
            );
        }
        else if (category.moneyFlow < 0) {                                                                            // < 0%
            return (
                <span className='price-down'>{category.moneyFlow.toFixed(2)}%<img alt='' src='/images/downarrowred.png' /></span>
            );
        }
        else                                                                                                                // 0.00%
            return (
                <span className='price-up'>{category.moneyFlow.toFixed(2)}%</span >
            );
    };


    return (
        <>
            <tr>
                <th colSpan={2}>
                    <div>{category.name}</div>
                </th>
            </tr>

            <tr>
                <td className="key">
                    Return YTD
                </td>
                <td className="value">
                    <p>
                        <img alt="" src={`/images/${category.rating || "na"}.jpg`} />
                        {category.ytd.toFixed(2)}%
                    </p>
                </td>
            </tr>

            <tr>
                <td className="key">
                    Category
                </td>
                <td className="value">
                    <p>
                        <img alt="" onClick={() => showCategoryFunds(category.name)} src="/images/list.png" />
                        {category.fundsInCategory} Funds ({category.percFundsInBuyTrade}% in BUY trend)
                    </p>

                </td>
            </tr>

            {!!category.moneyFlow &&
                <tr>
                    <td className="key">
                        Money Flow
                    </td>
                    <td className="value">
                        {moneyFlow()}
                    </td>
                </tr>
            }

            {renderBestFund()}

        </>
    );

};

export default CategoryRatingItem;
