import React, { MouseEvent } from "react";
import { TOnDelete } from "../../pages/EditPortfolio/EditPortfolio";
import { IFundItem } from "../models";
import "./PortfolioFundItem.css";

export interface IPortfolioFundItemProps {
    onDelete: TOnDelete;
    fund: IFundItem;
}


const PortfolioFundItem = (props: IPortfolioFundItemProps) => {

    const { onDelete, fund: { _id, name } } = props;



    /**
     * Delete fund from portfolio
     * 
     * @param  e
     * @param  fundId
     */
    const onClick = (e: MouseEvent, fundId: string) => {
        e.stopPropagation();
        onDelete(fundId);
    };


    return (
        <>
            <tr>
                <td className="editportfolio">
                    <span>{name}</span>
                    <img alt="" src='images/delete.png' onClick={(e) => onClick(e, _id)} />
                </td>
            </tr>
        </>
    );

};

export default PortfolioFundItem;
