import { TChartShowClick } from "../../Router";
import FundItem from "../FundItem/FundItem";
import { IFundItem } from "../models";
import React from "react";

interface IFundListProps {
    viewType?: string;
    funds: IFundItem[];
    onChartShow: TChartShowClick;
}


const FundList = (props: IFundListProps) => {
    const { viewType } = props;

    return (
        <table id="table">
            <tbody>
                {props.funds.map((fund: IFundItem) => (
                    <FundItem key={fund._id} fund={fund} viewType={viewType} onChartShow={props.onChartShow} />
                ))}
            </tbody>
        </table>
    );

};

export default FundList;
