import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { useParams } from "react-router";
import axios from "axios";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { getExpiryFromToken } from "../../services/Utils";
import { IOwaspResult, Owasp } from "../../services/owasp";


const ResetPassword = () => {

    const [formFields, setFormFields] = useState({
        password: "",
        repeatpassword: ""
    });

    const [passwordError, setPasswordError] = useState("");
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [resetToken, email] = (useParams() as any)["resetToken"].split("|");


    // Run every time
    useEffect(() => {

        // Enable submit button
        const enableButton = () => {
            let isValid = true;

            if (!formFields.password) isValid = false;
            if (isValidPassword(formFields.password)) isValid = false;
            if (!formFields.repeatpassword) isValid = false;
            if (formFields.password !== formFields.repeatpassword) isValid = false;

            setButtonEnabled(isValid);
        };

        let pwdError = "";
        if (formFields.password) pwdError = isValidPassword(formFields.password) as string;
        if (!pwdError && formFields.repeatpassword && formFields.password !== formFields.repeatpassword) pwdError = "Password does not match";
        setPasswordError(pwdError);

        enableButton();
    }, [formFields.password, formFields.repeatpassword]);



    // Validate OWASP password strength
    const isValidPassword = (password: string): string | undefined => {

        const owasp: Owasp = new Owasp({
            maxLength: 30,
            minLength: 8
        });

        const result: IOwaspResult = owasp.test(password);

        if (result.errors.length) {                                                                                         // Return first returned error
            return result.errors[0];
        }
        else return;
    };


    // Update state of email/password
    // Note: uses field "id"
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormFields({
            ...formFields,
            [e.target.id]: e.target.value,
        });
    };


    // Handle submit
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/v1/authentication/resetpassword`,
            data: {
                resetToken: resetToken,
                password: formFields.password
            }
        })
            .then(response => {
                const expires: number = getExpiryFromToken(response.data.data.token);                                       // Get expiry
                Cookies.set("sid", response.data.data.token, { expires });                                                  // Set token
                setPasswordUpdated(true);
            })
            .catch(() => {
                setPasswordError("Password reset token expired");
            });
    };

    if (passwordUpdated) return <Navigate to={"/"} />;

    return (
        <div id="pagewrapper" className="login-container">

            <form id="resetform" onSubmit={handleSubmit}>
                <input type="text" style={{ "display": "none" }} defaultValue={email} autoComplete="username" />

                <input id="password" className="loginInput" tabIndex={1} type="password" onChange={onChange} placeholder="password"
                    required autoComplete="new-password" />

                <input id="repeatpassword" className="loginInput" tabIndex={2} type="password" onChange={onChange} required placeholder="re-type password" autoComplete="new-password" />
                <div className="text-danger">{passwordError}</div>

                <button id="resetpassword" className="submitButton" tabIndex={3} type="submit" disabled={!buttonEnabled}>Reset Password</button>
            </form>

        </div>
    );
};

export default ResetPassword;
