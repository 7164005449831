import React from "react";
import { MouseEvent } from "react";
import { IMomentumBenchmark } from "../../pages/Momentum/Momentum";
import { TChartShowClick } from "../../Router";
import { StoreScrollPosition } from "../../services/PersistScrollPosition";
import { useNavigate } from "react-router-dom";



interface IMomentumItemProps {
    benchmark: IMomentumBenchmark;
    onChartShow: TChartShowClick;
}



const MomentumItem = (props: IMomentumItemProps) => {
    const { benchmark } = props;
    const history = useNavigate();


    /**
     * Show funds in category index
     * 
     * @param benchmark
     */
    const showCategoryFunds = (morningstarCategory: string) => {                                                            // Redirect to category index fund list
        StoreScrollPosition();
        history("/category/" + encodeURIComponent(morningstarCategory));
    };



    /**
     * Show chart
     * 
     * @param  e
     * @param  fundId
     */
    const showChart = (e: MouseEvent, fundId: string) => {
        e.stopPropagation();
        props.onChartShow(fundId);
    };



    // Add position change indicator
    const positionChange = () => {
        if (benchmark.rankingPosition === null) return;

        if (benchmark.rankingPosition === "UP") {                                                                           // BenchmarkIndex moved up in ranking
            return (
                <img alt='' src='/images/uparrowgreen.png' style={{ marginLeft: "10px" }} />
            );
        }
        else return (                                                                                                       // BenchmarkIndex moved down in ranking  
            <img alt='' src='/images/downarrowred.png' style={{ marginLeft: "10px" }} />
        );
    };



    // Render "Best Fund" element
    const renderBestFund = (benchmark: IMomentumBenchmark) => {
        if (benchmark.bestFund) {
            return (
                <tr>
                    <td className="key">Best Fund</td>
                    <td className="value">
                        <p>
                            <img alt="" onClick={(e) => showChart(e, benchmark.bestFund._id)} src="/images/graph.png" />{benchmark.bestFund.name}
                        </p>
                    </td>
                </tr>
            );
        }
        else return;
    };


    return (
        <>
            <tr>
                <th colSpan={2}>
                    <div>{benchmark.morningstarCategory}</div>
                </th>
            </tr>

            <tr>
                <td className="key">
                    Trailing Return
                </td>
                <td className="value">
                    <p>
                        <img alt="" src={`/images/${benchmark.rating || "na"}.jpg`} />
                        {benchmark.percentage.toFixed(2)}%
                        {positionChange()}
                    </p>
                </td>
            </tr>

            <tr>
                <td className="key">
                    Category
                </td>
                <td className="value">
                    <p>
                        <img alt="" onClick={() => showCategoryFunds(benchmark.morningstarCategory)} src="/images/list.png" />
                        {benchmark.fundsInCategory} Funds
                    </p>
                </td>
            </tr>

            {renderBestFund(benchmark)}
        </>
    );

};

export default MomentumItem;
