import React, { MouseEvent, useState, useEffect, FormEvent, ChangeEvent } from "react";
import Cookies from "js-cookie";
import "./Login.css";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { getExpiryFromToken } from "../../services/Utils";

const Login = () => {

    const [formFields, setFormFields] = useState({
        email: Cookies.get("email"),
        password: ""
    });

    const [rememberEmail, setRememberEmail] = useState(true);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [needPasswordReset, setNeedPasswordReset] = useState("");
    const [loginSuccessful, setLoginSuccessful] = useState(false);
    const [needSignUp, setNeedSignUp] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(false);


    // Run every time
    useEffect(() => {

        // Enable submit button
        const enableButton = () => {
            let isValid = true;

            if (!isEmail(formFields.email as string)) isValid = false;
            if (!formFields.password) isValid = false;                                                                                              // Errors are already displayed

            setButtonEnabled(isValid);
        };

        setEmailError(formFields.email && !isEmail(formFields.email) ? "Please enter a valid email" : "");

        enableButton();
    }, [formFields.email, formFields.password]);


    // Handle Login request
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/v1/authentication/login`,
            data: {
                email: formFields.email,
                password: formFields.password
            }
        })
            .then(response => {
                const expires: number = getExpiryFromToken(response.data.data.token);                                       // Get expiry
                Cookies.set("sid", response.data.data.token, { expires });                                                  // Set token expiry (days) in line with JWT expiry
                if (rememberEmail) Cookies.set("email", formFields.email as string, { expires: 365 });                      // Set email cookie for 1 year
                setLoginSuccessful(true);                                                                                   // Redirect to portfolio page
            })
            .catch(error => {
                if (error.message === "Network Error") {
                    setPasswordError("Service failure");
                }
                else setPasswordError("Invalid email or password");
            });
    };


    // Check if email is known
    const onForgotPassword = (e: MouseEvent) => {
        e.preventDefault();

        setPasswordError("");
        if (!isEmail(formFields.email as string)) return setEmailError("Please enter a valid email");

        axios({                                                                                                             // Get password reset token
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/v1/authentication/forgottenpassword`,
            data: {
                email: formFields.email
            }
        })
            .then(response => {
                setNeedPasswordReset(response.data.data.resetToken + "|" + formFields.email);
            })
            .catch(() => {
                setEmailError("Invalid email account");
            });
    };


    // Signup account
    const onSignUp = (e: MouseEvent) => {
        e.preventDefault();
        setNeedSignUp(true);
    };


    // Toggle remember email
    const onChangeRememberEmail = () => {
        setRememberEmail(!rememberEmail);
    };


    // Update state of email/password
    // Note: uses field "id"
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormFields({
            ...formFields,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [(e.target as any).id]: (e.target as any).value,
        });
    };


    // Is email valid?
    const isEmail = (email: string) => {
        const regex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return regex.test(email);
    };


    if (needPasswordReset) return <Navigate to={`/resetpassword/${needPasswordReset}`} />;
    if (loginSuccessful) return <Navigate to="/" />;
    if (needSignUp) return <Navigate to="/signup" />;

    return (
        <div id="pagewrapper" className="login-container">

            <form id="loginform" onSubmit={handleSubmit}>

                <input id="email" className="loginInput emailInput" tabIndex={1} type="text" defaultValue={formFields.email} placeholder="email" onChange={onChange} autoComplete="username" required />
                <div className="text-danger">{emailError}</div>

                <input id="password" className="loginInput" tabIndex={2} type="password" placeholder="password" onChange={onChange} autoComplete="current-password" required ></input>

                <input type="checkbox" checked={rememberEmail} onChange={onChangeRememberEmail} />
                <span>Remember me</span>

                <label className="resetpassword" htmlFor="password"><button className="resetpasswordbutton" type="button" onClick={onForgotPassword}>Forgot password</button></label>


                <div className="text-danger">{passwordError}</div>

                <button id="login" className="submitButton" tabIndex={4} type="submit" disabled={!buttonEnabled}>Log In</button>

                <div className="createaccount">
                    <span>or</span>
                </div>

                <div className="signup" onClick={onSignUp}><a tabIndex={-1} href="/signup">Create an account</a></div>
            </form>

        </div >
    );

};

export default Login;
