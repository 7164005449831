import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import axios, { AxiosRequestConfig } from "axios";
import FundList from "../../components/FundList/FundList";
import ETagCache from "../../services/ETagCache";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import PersistScrollPosition from "../../services/PersistScrollPosition";
import { TChartShowClick } from "../../Router";


interface ICategoryProps {
    onChartShow: TChartShowClick;
}



/**
 * List category funds
 */
const Category = (props: ICategoryProps) => {
    const [funds, setFunds] = useState([]);
    const history = useNavigate();

    const categoryName: string = useParams()?.["category"] || "";
    const updateScrollPosition = useRef(false);

    useEffect(() => {
        console.log("Load category funds", categoryName);

        ETagCache(axios as AxiosRequestConfig)({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            },
            data: {
                query: `{
            funds (morningstarCategory: "${categoryName}") {
                _id
                lastUpdated
                name
                isin
                yield
                tradeSignal
                fundRating
                fundSizeInMillions
                moneyFlow
                isRolling52WeekHigh
                dividendFrequency
                ytd
                netChangePercent
                ongoingCharge
                ratings {
                    morningstarRating
                }
                category {
                    name
                    ytd
                    rating
                }
                trailingPerformance {
                    M12
                    M36
                    M60
                }
                trailingStandardDeviation {
                    M12
                    M36
                    M60
                }
                medalistRating
            }
        }`
            }
        })
            .then(response => {
                console.log("Load category funds", response.data.data.funds.length);
                updateScrollPosition.current = true;
                setFunds(response.data.data.funds);
            })
            .catch(() => {
                history("/login");
            });

    }, [categoryName]);

    return (
        <div id="pagewrapper">
            <h2>{categoryName}</h2>
            <FundList funds={funds} viewType="category" onChartShow={props.onChartShow} />
            <PersistScrollPosition updateScrollPosition={updateScrollPosition.current} />

            <div onClick={() => history(-1)} className="backbutton">
                <button>Back</button>
            </div>
        </div>
    );

};

export default Category;
