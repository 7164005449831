import React from "react";
import { TOnDelete } from "../../pages/EditPortfolio/EditPortfolio";
import { IFundItem } from "../models";
import PortfolioFundItem from "../PortfolioFundItem/PortfolioFundItem";

interface IPortfolioFundListProps {
    onDelete: TOnDelete;
    funds: IFundItem[];
}


const PortfolioFundList = (props: IPortfolioFundListProps) => {

    const { onDelete } = props;

    return (
        <table id="table">
            <tbody>
                {props.funds.map(fund => (
                    <PortfolioFundItem key={fund._id} fund={fund} onDelete={onDelete} />
                ))}
            </tbody>
        </table>
    );

};

export default PortfolioFundList;
