import React, { useState, useEffect, FormEvent } from "react";
import Autosuggest, { SuggestionSelectedEventData } from "react-autosuggest";
import axios, { AxiosRequestConfig } from "axios";
import PortfolioFundList from "../../components/PortfolioFundList/PortfolioFundList";
import parse from "html-react-parser";
import ETagCache from "../../services/ETagCache";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { IFundItem } from "../../components/models";

export type TOnDelete = (fundId: string) => void;


const EditPortfolio = () => {

    const [funds, setFunds] = useState([]);
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const history = useNavigate();

    const refreshPortfolio = () => {
        ETagCache(axios as AxiosRequestConfig)({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            },
            data: {
                query: `{
                    portfolio {
                        funds {
                            _id
                            name
                        }
                    }
                }`
            }
        })
            .then(response => {
                setFunds(response.data.data.portfolio.funds);
            })
            .catch(() => {
                history("/login");
            });
    };


    useEffect(() => {
        refreshPortfolio();
    }, []);


    /**
     * Get suggestions for value
     * 
     * @param  value
     */
    const getSuggestions = async (value: string) => {
        const response = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            },
            data: {
                query: `{
                    funds (name: "${value}", limit: 10) {
                        _id
                        name
                    }
                }`
            }
        });


        // check handling invalid characters!

        if (response.data.errors) {
            history("/login");
        }

        return response.data;
    };


    /**
     * On suggestion selection, update input
     *
     * @param  suggestion
     */
    const getSuggestionValue = (suggestion: IFundItem) => suggestion.name;


    /**
    * Mark value in suggestion as <strong>
    *
    * @param  value
    * @param  textValue
    */
    const highlightSearch = (value: string, textValue: string) => {
        return parse(textValue.replace(new RegExp("(" + value + ")", "gi"), "<strong>$1</strong>"));
    };


    /**
    * Render suggestion
    *
    * @param  suggestion
    */
    const renderSuggestion = (suggestion: IFundItem) => (
        <div>
            {highlightSearch(value, suggestion.name)}
        </div>
    );



    /**
    * On Change event
    *
    * @param  event
    * @param  newValue
    */
    const onChange = (event: FormEvent<HTMLElement>, { newValue }: { newValue: string; }) => {
        setValue(newValue);
    };



    /**
     * Get suggested funds
     * 
     * @param value
     */
    const onSuggestionsFetchRequested = ({ value }: { value: string; }) => {
        getSuggestions(value)
            .then(data => {
                if (data.Error) {
                    setSuggestions([]);
                }
                else {
                    if (data.data.funds.length === 1) {
                        addFund(data.data.funds[0]._id);
                        onSuggestionsClearRequested();
                    }
                    else setSuggestions(data.data.funds);
                }
            });
    };


    const onSuggestionsClearRequested = () => {
        setValue("");
        setSuggestions([]);
    };


    // Default value
    const inputProps = {
        placeholder: "Enter fund name",
        value,
        autoFocus: true,
        onChange
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSuggestionSelected = (event: FormEvent<any>, { suggestion }: SuggestionSelectedEventData<IFundItem>) => {
        event.stopPropagation();
        addFund(suggestion._id);
    };




    // Add fund to list
    const addFund = (fundId: string) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/v1/portfolio/${fundId}`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            }
        })
            .then(() => {
                refreshPortfolio();
            })
            .catch(() => {
                history("/login");
            });
    };


    const onDelete = (fundId: string) => {
        axios({
            method: "DELETE",
            url: `${process.env.REACT_APP_SERVICE_HOST}/v1/portfolio/${fundId}`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            }
        })
            .then(() => {
                refreshPortfolio();
            })
            .catch(() => {
                history("/login");
            });
    };


    return (
        <div id="pagewrapper" className="searchpanel">
            <div id="search">
                <div className="shadow">
                    <img alt="" src="/images/search.png" />
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        onSuggestionSelected={onSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                </div>
            </div>

            <h2>Portfolio</h2>
            <PortfolioFundList funds={funds} onDelete={onDelete} />
        </div>
    );

};

export default EditPortfolio;
