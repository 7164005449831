import React, { useEffect, useState, useRef } from "react";
import axios, { AxiosRequestConfig } from "axios";
import CategoryRatingList from "../../components/CategoryRatingList/CategoryRatingList";
import ETagCache from "../../services/ETagCache";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import PersistScrollPosition from "../../services/PersistScrollPosition";
import { TChartShowClick } from "../../Router";


export interface ICategoryRating {
    name: string;
    fundsInCategory: string;
    percFundsInBuyTrade: number;
    ytd: number;
    rating: number;
    moneyFlow: number;
    bestFund: {
        _id: string;
        name: string;
        ytd: number;
    };
}


interface ICategoryRatingsProps {
    onChartShow: TChartShowClick;
}


const CategoryRatings = (props: ICategoryRatingsProps) => {
    const history = useNavigate();
    const [categoryRatings, setCategoryRatings] = useState([]);
    const updateScrollPosition = useRef(false);

    useEffect(() => {
        ETagCache(axios as AxiosRequestConfig)
            .post(
                `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
                {
                    query: `{
                        categories {
                            name
                            fundsInCategory
                            percFundsInBuyTrade
                            ytd
                            rating
                            moneyFlow
                            bestFund {
                                _id
                                name
                                ytd
                            }
                        }
                    }`
                },
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get("sid")}`
                    }
                }
            )
            .then(response => {
                console.log("Loaded CategoryRatings", response.data.data.categories.length);
                updateScrollPosition.current = true;
                setCategoryRatings(response.data.data.categories);
            })
            .catch(() => {
                history("/login");
            });

    }, []);

    return (
        <div id="pagewrapper">
            <h2>Categories</h2>
            <CategoryRatingList categoryRatings={categoryRatings} onChartShow={props.onChartShow} />
            <PersistScrollPosition updateScrollPosition={updateScrollPosition.current} />
        </div>
    );

};

export default CategoryRatings;
